/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NoMatchPage } from 'configs/routes';
import { ActionContext } from './MainContainer';
import { DOMAIN_TYPE, IMAGE_PATH, OPTION_LOGIN, STORAGE_KEYS, USER_CONFIG } from 'configs/AppConfig';
import { getCachedRoutePath } from 'state/route/routeSelector';
import { connect, useSelector } from 'react-redux';
import { getCompanyDomain, getPageFavicon, getViewSettings } from 'state/company/companySelector';
import { getAuthUser, isAuthenticated } from 'state/session/sessionSelector';
import { getGoogleOption, getIsLoadSetting, getMicrosoftLoginOption } from 'state/search/searchSelector';
import { Grid } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import StorageHelper from 'utils/StorageHelper';

const UnauthenticatedRoute = props => {
  const {
    children,
    cachedRoute,
    pageFavicon,
    sessionActions: { clearAuthInfo, getAuthInfo }
  } = props;

  const urlUpload = process.env.REACT_APP_UPLOAD_PATH + '/';
  const pathname = window.location.pathname;
  const search = window.location.search;

  const isLoadSetting = useSelector(getIsLoadSetting);
  const viewSettings = useSelector(getViewSettings);
  const isOpenType = viewSettings.accountType === OPTION_LOGIN.OPEN_TYPE;
  const authUser = useSelector(getAuthUser);
  const googleOption = useSelector(getGoogleOption);
  const microsoftLoginOption = useSelector(getMicrosoftLoginOption);

  const isAuthen = useSelector(isAuthenticated);
  const currentServicePath = StorageHelper.getSessionItem(STORAGE_KEYS.path);
  const currentDomain = StorageHelper.getSessionItem(STORAGE_KEYS.domain);
  const appMobileAuthToken = StorageHelper.getLocalItem(STORAGE_KEYS.appMobileAuthToken);
  const userStore = !currentServicePath || currentServicePath === '' ? currentDomain : currentServicePath;
  const isToken = StorageHelper.getCookie(userStore);
  const isGoogleLogin = pathname.includes('/login') && search && search === '?google=true&fl=true';
  const isMicrosoftLogin = pathname.includes('/login') && search && search === '?microsoft=true&fl=true';
  const isLoginMobileApp = pathname.includes('/login') && search && search === '?is_login_mobile_app=true';

  const isFullDomain = viewSettings.domainType === DOMAIN_TYPE.FULL_DOMAIN;

  useEffect(() => {
    if (!isAuthen && isToken) {
      getAuthInfo();
    }
  }, []);

  useEffect(() => {
    if (pageFavicon) {
      const favicon = document.getElementById('favicon');
      favicon.href = `${urlUpload}${IMAGE_PATH.FAVICON}${pageFavicon}`;
      favicon.type = 'image/png';
    }
  }, [pageFavicon]);

  useEffect(() => {
    if (googleOption && isGoogleLogin) {
      authUser && authUser.roleLevel && clearAuthInfo();
      StorageHelper.removeCookie(userStore, isFullDomain ? { path: '/' } : {});
      StorageHelper.removeLocalItem(userStore);
    }
  }, [isGoogleLogin]);
  useEffect(() => {
    if (isLoginMobileApp && !appMobileAuthToken) {
      authUser && authUser.roleLevel && clearAuthInfo();
      StorageHelper.removeCookie(userStore, isFullDomain ? { path: '/' } : {});
      StorageHelper.removeLocalItem(userStore);
    }
  }, [isLoginMobileApp]);

  useEffect(() => {
    if (microsoftLoginOption && isMicrosoftLogin) {
      authUser && authUser.roleLevel && clearAuthInfo();
      StorageHelper.removeCookie(userStore, isFullDomain ? { path: '/' } : {});
      StorageHelper.removeLocalItem(userStore);
    }
  }, [isMicrosoftLogin]);

  if (isLoadSetting || !viewSettings) {
    return (
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh', backgroundColor: '#FFF' }}
      >
        <Spinner color="primary" style={{ marginRight: 20 }} />
      </Grid>);
  } else {
    if (isOpenType) {
      return <Redirect to={'/'} push />;
    } else {
      if (!isAuthen) {
        return children;
      } else {
        if (authUser.roleLevel === USER_CONFIG.SYSTEM_ADMIN.roleLevel || authUser.roleLevel === USER_CONFIG.COMPANY_ADMIN.roleLevel || authUser.roleLevel === USER_CONFIG.SERVICE_ADMIN.roleLevel) {
          return <NoMatchPage/>;
        } else {
          if (cachedRoute.pathname) {
            return <Redirect to={{ pathname: cachedRoute.pathname, search: cachedRoute.search }} push />;
          }
          if (isGoogleLogin) {
            return <Redirect to={'/login?google=true&fl=true'} push />;
          } else if (isMicrosoftLogin) {
            return <Redirect to={'/login?microsoft=true&fl=true'} push />;
          } else if (isLoginMobileApp && viewSettings && viewSettings.isEnableWebMobile) {
            return children;
          } else {
            return <Redirect to={'/'} push />;
          }
        }
      }
    }
  }
};

UnauthenticatedRoute.propsType = {
  sessionActions: PropTypes.object,
  cachedRoute: PropTypes.object,
  children: PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    cachedRoute: getCachedRoutePath(state),
    pageFavicon: getPageFavicon(state),
    domain: getCompanyDomain(state)
  };
};

export default connect(mapStateToProps)(props => (
  <ActionContext.Consumer>
    {({ sessionActions }) => <UnauthenticatedRoute
      {...props}
      sessionActions={sessionActions}
    />
    }
  </ActionContext.Consumer>
));
