import {
  put,
  cancelled,
  take,
  fork,
  cancel,
  call,
  select,
  all,
  takeEvery
} from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';

import createRequest from 'api/httpRequest';
import SessionTypes from './SessionTypes';
import StorageHelper from 'utils/StorageHelper';
import { FORMS, STORAGE_KEYS, AUTHEN_TYPES, DOMAIN_TYPE, LAYOUT_SETTING } from 'configs/AppConfig';
import API_AUTHEN from 'api/authentication';
import { cacheRoute, changeRoute } from 'state/route/RouteActions';
import { getCachedRoutePath, getHistory } from 'state/route/routeSelector';
import RouteTypes from 'state/route/RouteTypes';
import camelcaseKeys from 'camelcase-keys';
import Util from 'utils/Util';
import { changeLocale } from 'state/locale/LocaleActions';
import CompanyTypes from 'state/company/CompanyTypes';
import { getCompanyDomain, getServicePath, getViewSettings } from 'state/company/companySelector';
import { DEFAULT_LOCALE } from 'configs/locale';
// import moment from 'moment-timezone';
import * as APIStatus from 'configs/ApiStatus';
import ErrorTypes from 'state/handler/ErrorTypes';
import { getDomainType, getLayoutViewPage } from 'state/search/searchSelector';

let loginTask;

export default function * sessionSaga () {
  yield all([
    sessionLifeCycleSaga(),
    restoreSessionSaga(),
    getAuthInfo()
  ]);
}

/**
 * Some rules:
 * - Token just stored in session storage [don't store in local storage]
 * - Credentials always stored in session storage [if remember login is true then store both session & local storage]
 */
function * sessionLifeCycleSaga () {
  while (true) {
    // init session
    const currentDomain = yield select(getCompanyDomain);
    const servicePath = yield select(getServicePath);
    const userStore = !servicePath || servicePath === '' ? currentDomain : servicePath;

    const authToken = yield call(
      StorageHelper.getCookie,
      userStore
    );
    const authUser = yield call(
      StorageHelper.getLocalObject,
      userStore
    );
    const isAuthenGoogleLogin = yield call(
      StorageHelper.getLocalItem,
      STORAGE_KEYS.googleLogin
    );
    if (authToken && authToken !== 'null') {
      // there is token in session => validate token state
      yield fork(validateSession);
    } else {
      if (authUser && authUser.id) {
        yield call(StorageHelper.removeLocalItem, userStore);
      }
      if (isAuthenGoogleLogin) {
        yield call(
          StorageHelper.setLocalObject,
          STORAGE_KEYS.googleLogin,
          { isGoogleLogin: false }
        );
      }
      // wait for login request...
      const { payload } = yield take(SessionTypes.LOG_IN_REQUEST);

      // notify for redux-form request is handling
      yield put(startSubmit(FORMS.login));
      // fork return a Task object (a task with mission handle login request)
      loginTask = yield fork(authorize, payload);
    }

    // after logged in, wait some another request [log out, reauthorize when token expired...]
    const action = yield take([
      SessionTypes.LOG_OUT,
      SessionTypes.REAUTHORIZE, // call API return status 401
      SessionTypes.LOG_IN_ERROR,
      SessionTypes.RESTORE_SESSION_FAILURE,
      SessionTypes.CLEAR_TOKEN
    ]);
    switch (action.type) {
      case SessionTypes.LOG_OUT:
        yield call(logout);
        break;

      case SessionTypes.REAUTHORIZE:
      case SessionTypes.RESTORE_SESSION_FAILURE:
      case SessionTypes.CLEAR_TOKEN:
        yield clearToken();
        break;

      default:
      // don't do anything
    }
  }
}

function * logout () {
  // cancel login if request is handling
  if (loginTask) {
    yield cancel(loginTask);
  }

  yield createRequest({
    api: API_AUTHEN.LOG_OUT
  });

  yield put({ type: SessionTypes.LOG_OUT_SUCCESS });
  yield clearToken();
}

function * clearToken () {
  const currentDomain = yield select(getCompanyDomain);
  const servicePath = yield select(getServicePath);
  const viewSettings = yield select(getViewSettings);
  const userStore = !servicePath || servicePath === '' ? currentDomain : servicePath;
  const loginPath = !servicePath || servicePath === '' ? '/login' : `/${servicePath}/login`;
  const isFullDomain = viewSettings.domainType === DOMAIN_TYPE.FULL_DOMAIN;
  var pathSearch = '';
  const authToken = yield call(
    StorageHelper.getCookie,
    userStore
  );
  const authUser = yield call(
    StorageHelper.getLocalObject,
    userStore
  );
  const appMobileAuthToken = yield call(
    StorageHelper.getLocalItem,
    STORAGE_KEYS.appMobileAuthToken
  );
  const cacheLoginMobileApp = yield call(
    StorageHelper.getCookie,
    STORAGE_KEYS.cacheLoginMobileApp
  );
  if (cacheLoginMobileApp) {
    pathSearch = '?is_login_mobile_app=true';
  }
  if (!isFullDomain) {
    yield call(StorageHelper.removeCookie, userStore, {
      path: '/'
    });
  }
  if (authToken || authUser || appMobileAuthToken) {
    yield call(StorageHelper.removeCookie, userStore, isFullDomain ? { path: '/' } : {});
    
    yield call(StorageHelper.removeLocalItem, userStore);
    yield call(
      StorageHelper.setLocalObject,
      STORAGE_KEYS.googleLogin,
      { isGoogleLogin: false }
    );
    yield call(
      StorageHelper.setLocalObject,
      STORAGE_KEYS.microsoftLogin,
      { isMicrosoftLogin: false }
    );
    if (appMobileAuthToken) {
      pathSearch = '?is_login_mobile_app=true';
      const cookiesOptions = isFullDomain ? { maxAge: 60, path: '/' } : { maxAge: 60 };
      yield call(StorageHelper.setCookie, STORAGE_KEYS.cacheLoginMobileApp, true, cookiesOptions);
      
      yield call(StorageHelper.removeLocalItem, STORAGE_KEYS.appMobileAuthToken);
    }
    yield put(changeRoute(loginPath, pathSearch));

    yield put(changeLocale(Util.getBrowserLanguage() || DEFAULT_LOCALE));
    yield put(cacheRoute(null, null));
  }
}

function * authorize ({ email, password, rememberMe, ip, loginType, loginRole, authorizeCode, redirectUrl, isAcceptTermsAndCondition, isSubscribeMail, codeVerifier, isAuto, isMobileApp }) {
  const currentDomain = yield select(getCompanyDomain);
  const servicePath = yield select(getServicePath);
  const domainType = yield select(getDomainType);
  const layoutViewPage = yield select(getLayoutViewPage);
  const isFullDomain = domainType === DOMAIN_TYPE.FULL_DOMAIN;
  const userStore = !servicePath || servicePath === '' ? currentDomain : servicePath;
  const loginPath = !servicePath || servicePath === '' ? '/login' : `/${servicePath}/login`;
  const contentCallback = StorageHelper.getSessionItem(STORAGE_KEYS.urlContentCallback);
  const viewCallback = StorageHelper.getSessionItem(STORAGE_KEYS.urlViewCallback);
  const urlSubscDetail = StorageHelper.getSessionItem(STORAGE_KEYS.urlSubscDetail);
  let api = Object.assign({}, API_AUTHEN.LOG_IN, {
    data: {
      email: email,
      keep_login: rememberMe,
      password_hash: password,
      login_type: loginRole,
      domain: currentDomain,
      path: servicePath
    }
  });
  let ignoreErrorMessage = false;
  switch (loginType) {
    case AUTHEN_TYPES.IP:
      api = Object.assign({}, API_AUTHEN.LOG_IN_BY_IP_ADDRESS, {
        data: {
          client_ip_address: ip,
          keep_login: rememberMe,
          company_domain: currentDomain,
          path: servicePath
        }
      });
      break;
    case AUTHEN_TYPES.AUTO_IP:
      api = Object.assign({}, API_AUTHEN.AUTO_LOG_IN_BY_IP_ADDRESS, {
        data: {
          client_ip_address: ip,
          keep_login: rememberMe,
          company_domain: currentDomain,
          path: servicePath
        }
      });

      ignoreErrorMessage = true;
      break;
    case AUTHEN_TYPES.GOOGLE_LOGIN:
      api = Object.assign({}, API_AUTHEN.LOGIN_WITH_GOOGLE, {
        data: {
          domain: currentDomain,
          keep_login: rememberMe,
          path: servicePath,
          authorize_code: authorizeCode,
          redirect_url: redirectUrl
        }
      });
      break;
    case AUTHEN_TYPES.GAKKEN_ID:
      api = Object.assign({}, API_AUTHEN.LOGIN_GAKKEN_ID, {
        data: {
          domain: currentDomain,
          keep_login: rememberMe,
          path: servicePath,
          authorize_code: authorizeCode,
          redirect_url: redirectUrl,
          is_accept_terms_and_condition: isAcceptTermsAndCondition,
          is_subscribe_mail: isSubscribeMail,
          is_mobile_app: isMobileApp
        }
      });
      break;
    case AUTHEN_TYPES.MICROSOFT_LOGIN:
      api = Object.assign({}, API_AUTHEN.LOGIN_WITH_MICROSOFT, {
        data: {
          domain: currentDomain,
          keep_login: rememberMe,
          path: servicePath,
          authorize_code: authorizeCode,
          redirect_url: redirectUrl,
          code_verifier: codeVerifier
        }
      });
      break;
    default:
      break;
  }
  yield createRequest({
    api: api,
    ignoreErrorMessage: true,
    onSuccess: function * ({ data, status }) {
      if (data && status === 200) {
        const res = Util.toCamelCaseKey(data);
        let user = {
          companyId: res.companyId,
          domain: res.domain,
          email: res.email,
          name: res.fullName,
          id: res.id,
          lang: res.lang ? res.lang : Util.getBrowserLanguage() || DEFAULT_LOCALE,
          roleLevel: res.roleLevel
        };
        if (loginType !== AUTHEN_TYPES.EMAIL && loginType !== AUTHEN_TYPES.GOOGLE_LOGIN) {
          user = {
            companyId: res.companyId,
            domain: res.domain,
            id: res.id,
            name: res.name,
            roleLevel: res.roleLevel,
            lang: res.lang ? res.lang : Util.getBrowserLanguage() || DEFAULT_LOCALE
          };
        }
        // const now = moment();
        // const expireDate = moment.utc(res.expireDate).local();
        // const diff = expireDate.diff(now) / 1000;
        const cookiesOptions = isFullDomain ? { maxAge: 60 * 60 * 24 * 365, path: '/' } : { maxAge: 60 * 60 * 24 * 365 };
        yield call(StorageHelper.setCookie, userStore, res.accessToken, cookiesOptions);

        yield call(StorageHelper.setSessionItem, STORAGE_KEYS.path, servicePath);
        yield put(changeLocale(user.lang));
        // const contentCallback = StorageHelper.getSessionItem(STORAGE_KEYS.pathContentCallback);
        // const viewCallback = StorageHelper.getSessionItem(STORAGE_KEYS.pathViewCallback);
        yield call(
          StorageHelper.setLocalObject,
          STORAGE_KEYS.googleLogin,
          { isGoogleLogin: loginType === AUTHEN_TYPES.GOOGLE_LOGIN }
        );
        yield call(
          StorageHelper.setLocalObject,
          STORAGE_KEYS.microsoftLogin,
          { isMicrosoftLogin: loginType === AUTHEN_TYPES.MICROSOFT_LOGIN }
        );
        const cacheStorage = StorageHelper.getSessionObject(STORAGE_KEYS.cacheStorage);

        switch (loginType) {
          case AUTHEN_TYPES.GOOGLE_LOGIN:
          case AUTHEN_TYPES.MICROSOFT_LOGIN:
            yield put({
              type: SessionTypes.LOG_IN_SUCCESS,
              payload: {
                accessToken: res.accessToken,
                user: user,
                isGoogleLogin: loginType === AUTHEN_TYPES.GOOGLE_LOGIN,
                isMicrosoftLogin: loginType === AUTHEN_TYPES.MICROSOFT_LOGIN
              }
            });
            if (contentCallback) {
              window.location.replace(`${contentCallback}`);
              StorageHelper.removeSessionItem(STORAGE_KEYS.urlContentCallback);
            }
            if (viewCallback) {
              window.location.replace(`${viewCallback}`);
              StorageHelper.removeSessionItem(STORAGE_KEYS.urlViewCallback);
            }
            if (cacheStorage && !cacheStorage.cacheRoute.includes('login')) {
              yield put(changeRoute(`${cacheStorage.cacheRoute}`, ''));
            } else {
              StorageHelper.removeSessionItem(STORAGE_KEYS.cacheStorage);
            }
            if (urlSubscDetail) {
              window.location.replace(`${urlSubscDetail}`);
            }
            break;
          default:
            yield call(
              StorageHelper.setLocalObject,
              userStore,
              { ...user }
            );
            yield put({
              type: SessionTypes.LOG_IN_SUCCESS,
              payload: { accessToken: res.accessToken, user: user, isGoogleLogin: false, isMicrosoftLogin: false, isMobileApp }
            });
            if (isMobileApp && loginType === AUTHEN_TYPES.GAKKEN_ID) {
              if (res.appMobileAuthToken && res.expireDate) {
                yield call(StorageHelper.setLocalItem, STORAGE_KEYS.appMobileAuthToken, res.appMobileAuthToken);
                yield put({
                  type: SessionTypes.CALL_START_APP_WITH_CUSTOM_AUTH,
                  payload: true
                });
                // window.location.replace(`${window.location.href}?UserID=${res.accessToken}`);
                yield put({
                  type: RouteTypes.CHANGE_ROUTE,
                  payload: {
                    pathname: `/${isFullDomain ? '' : servicePath}${layoutViewPage === LAYOUT_SETTING.SEARCH_LAYOUT.id ? '/search' : ''}`,
                    search: `UserID=${res.accessToken}`
                  }
                });
              }
            }
            if (urlSubscDetail && loginType === AUTHEN_TYPES.GAKKEN_ID) {
              window.location.replace(`${urlSubscDetail}`);
            }
            if (contentCallback) {
              window.location.replace(`${contentCallback}`);
              StorageHelper.removeSessionItem(STORAGE_KEYS.urlContentCallback);
            }
            if (viewCallback) {
              window.location.replace(`${viewCallback}`);
              StorageHelper.removeSessionItem(STORAGE_KEYS.urlViewCallback);
            }
            break;
        }

        yield put(stopSubmit(FORMS.login));
        yield fork(validateSession);
      } else {
        console.error('Can not read "access_token" from response');
      }
    },
    onError: function * (error, response) {
      if (contentCallback) {
        StorageHelper.removeSessionItem(STORAGE_KEYS.urlContentCallback);
      }
      if (viewCallback) {
        StorageHelper.removeSessionItem(STORAGE_KEYS.urlViewCallback);
      }
      switch (loginType) {
        case AUTHEN_TYPES.GOOGLE_LOGIN:
          // status 890, 889, 888, 887, 830, 811, 713, 703, 701, 502, 428, 427, 413, 400
          if (error.status === APIStatus.ERR_LOGIN_ID_ALREADY_EXISTED.status ||
            error.status === APIStatus.ERR_LOGIN_ID.status ||
            error.status === APIStatus.ERR_CODE.status ||
            error.status === APIStatus.ERR_TOKEN.status ||
            error.status === APIStatus.ERR_USER_BLOCKED.status ||
            error.status === APIStatus.ERR_USER_ACCESS_EXPIRED.status ||
            error.status === APIStatus.ERR_USER_GROUP_NOT_FOUND.status ||
            error.status === APIStatus.ERR_COMPANY_NOT_FOUND.status ||
            error.status === APIStatus.ERR_USER_NOT_FOUND.status ||
            error.status === APIStatus.ERR_BOOKEND_API.status ||
            error.status === APIStatus.ERR_COMPANY_MAX_SESSION.status ||
            error.status === APIStatus.ERR_USER_GROUP_MAX_SESSION.status ||
            error.status === APIStatus.ERR_EMAIL_ALREADY_EXISTED.status ||
            error.status === APIStatus.ERR_BAD_REQUEST.status
          ) {
            yield put({ type: SessionTypes.CACHE_PREV_LOGIN_ROUTE });
            yield put({ type: SessionTypes.LOG_IN_ERROR });
          }
          break;
        case AUTHEN_TYPES.MICROSOFT_LOGIN:
          // status 891, 890, 889, 888, 887, 830, 811, 713, 703, 701, 502, 428, 427, 413, 403, 400

          if (error.status === APIStatus.ERR_MICROSOFT_NAME_EMPTY.status ||
            error.status === APIStatus.ERR_LOGIN_ID_ALREADY_EXISTED.status ||
            error.status === APIStatus.ERR_LOGIN_ID.status ||
            error.status === APIStatus.ERR_CODE.status ||
            error.status === APIStatus.ERR_TOKEN.status ||
            error.status === APIStatus.ERR_USER_BLOCKED.status ||
            error.status === APIStatus.ERR_USER_ACCESS_EXPIRED.status ||
            error.status === APIStatus.ERR_USER_GROUP_NOT_FOUND.status ||
            error.status === APIStatus.ERR_COMPANY_NOT_FOUND.status ||
            error.status === APIStatus.ERR_USER_NOT_FOUND.status ||
            error.status === APIStatus.ERR_BOOKEND_API.status ||
            error.status === APIStatus.ERR_COMPANY_MAX_SESSION.status ||
            error.status === APIStatus.ERR_USER_GROUP_MAX_SESSION.status ||
            error.status === APIStatus.ERR_EMAIL_ALREADY_EXISTED.status ||
            error.status === APIStatus.ERR_BAD_REQUEST.status ||
            error.status === APIStatus.ERR_FORBIDDEN.status
          ) {
            yield put({ type: SessionTypes.CACHE_PREV_LOGIN_ROUTE });
            yield put({ type: SessionTypes.LOG_IN_ERROR });
          }
          break;
        case AUTHEN_TYPES.GAKKEN_ID:
          yield put(stopSubmit(FORMS.login));
          yield put({ type: SessionTypes.LOG_IN_ERROR });

          switch (error.status) {
            case APIStatus.ERR_AUTHORIZE_CODE_INVALID.status:
              yield put(changeRoute(loginPath, ''));
              break;
            case APIStatus.ERR_ACCOUNT_NOT_EXIST.status:
              yield put({ type: SessionTypes.ALLOW_GAKKEN_TERMS_REQUEST });
              yield put({ type: CompanyTypes.GET_GAKKEN_TERMS_REQUEST });
              ignoreErrorMessage = true;
              break;
            case APIStatus.ERR_ACCOUNT_NOT_HAVE_EMAIL.status:
              yield put({ type: SessionTypes.SHOW_GAKKEN_MY_PAGE });
              break;
            default:
              yield put(changeRoute(loginPath, ''));
              break;
          }
          break;

        default:
          yield put(stopSubmit(FORMS.login, { _error: 'api.error.' + error.status }));
          yield put({ type: SessionTypes.LOG_IN_ERROR });
          break;
      }

      if (!ignoreErrorMessage) {
        if (loginType === AUTHEN_TYPES.GAKKEN_ID && response.config.url === '/auth/gakken-id-login' && response.data.status === 701 && !isAuto) {
          yield put({ type: ErrorTypes.API_RESPONSE_ERROR, response: { ...response, changeMessage: 'not_register' } });
        } else {
          yield put({ type: ErrorTypes.API_RESPONSE_ERROR, response: response });
        }
      }
    },
    onRequestError: function * () {
      yield put(stopSubmit(FORMS.login));
      yield put({ type: SessionTypes.LOG_IN_ERROR });
    }
  });

  if (yield cancelled()) {
    // ... put special cancellation handling code here
  }
}

function * getAuthInfo () {
  yield takeEvery(SessionTypes.GET_AUTH_INFO_REQUEST, validateSession);
}

function * validateSession () {
  const currentDomain = yield select(getCompanyDomain);
  const servicePath = yield select(getServicePath);
  const userStore = !servicePath || servicePath === '' ? currentDomain : servicePath;
  const params = {
    servicePath
  };
  yield createRequest({
    api: {
      ...API_AUTHEN.GET_AUTH_INFO,
      params: params
    },
    onSuccess: function * ({ data }) {
      const user = camelcaseKeys(data);
      yield call(
        StorageHelper.setLocalObject,
        userStore,
        user
      );
      yield put(changeLocale(user.lang || Util.getBrowserLanguage() || DEFAULT_LOCALE));
      yield put({ type: SessionTypes.RESTORE_SESSION_SUCCESS, payload: user });
    },
    onError: function * () {
      // cache route to restore session after login
      yield put({ type: SessionTypes.CACHE_PREV_LOGIN_ROUTE });
      yield put({ type: SessionTypes.RESTORE_SESSION_FAILURE });
    }
  });

  if (yield cancelled()) {
    // ... put special cancellation handling code here
  }
}

/**
 * There are 3 place dispatch `SessionTypes.CACHE_PREV_LOGIN_ROUTE` action
 * 1. errorSaga - handleApiError(): in case API return status code 401
 * 2. sessionSaga - validateSession(): in case validate session failure [token is invalid or expired]
 * 3. AuthenticatedRoute component - getDerivedStateFromProps(): in case access a router required authenticated but not yet authenticated
 */
// restore the last accessed route after login
function * restoreSessionSaga () {
  // only active after set history
  yield take(RouteTypes.SET_HISTORY);
  while (true) {
    const { pathname } = yield select(getCachedRoutePath);
    if (!pathname) {
      // update current route before redirect to login page
      yield take(SessionTypes.CACHE_PREV_LOGIN_ROUTE);
      // get current route
      const {
        location: { pathname, search }
      } = yield select(getHistory);

      const cacheLoginMobileApp = yield call(
        StorageHelper.getCookie,
        STORAGE_KEYS.cacheLoginMobileApp
      );
      const pathSearch = cacheLoginMobileApp ? '?is_login_mobile_app=true' : '';
      if (pathname.includes('/login') && search) {
        yield put(changeRoute(`${pathname}`, cacheLoginMobileApp ? pathSearch : null));
      } else {
        yield put(cacheRoute('/', search));
      }
    }

    const action = yield take([
      SessionTypes.LOG_IN_SUCCESS,
      SessionTypes.REAUTHORIZE, // call API return status 401
      SessionTypes.RESTORE_SESSION_FAILURE
    ]);
    switch (action.type) {
      case SessionTypes.LOG_IN_SUCCESS:
        // restore the last route
        // eslint-disable-next-line no-case-declarations
        // const { pathname, search } = yield select(getCachedRoutePath);
        // console.log('window', window.location.pathname);
        // if (pathname) {
        //   yield put(changeRoute('/', search));
        //   yield put(cacheRoute(null, null));
        // }
        break;

      case SessionTypes.REAUTHORIZE:
      case SessionTypes.RESTORE_SESSION_FAILURE:
        yield clearToken();
        break;

      default:
        break;
    }
  }
}
