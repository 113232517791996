import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import AccountTypes from './AccountTypes';
import createRequest from 'api/httpRequest';
import NotificationTypes from 'state/notification/NotificationTypes';
import StorageHelper from 'utils/StorageHelper';
import { DOMAIN_TYPE, STORAGE_KEYS, USER_CONFIG } from 'configs/AppConfig';
import API_USER from 'api/user';
import Util from 'utils/Util';
import { changeRoute } from 'state/route/RouteActions';
import { changeLocale } from 'state/locale/LocaleActions';
import { getAuthUser } from 'state/session/sessionSelector';
import SessionTypes from 'state/session/SessionTypes';
import CompanyTypes from 'state/company/CompanyTypes';
import { getCompanyDomain, getServicePath, getViewSettings } from 'state/company/companySelector';
import { getDomainType } from 'state/search/searchSelector';
import { DEFAULT_LOCALE } from 'configs/locale';

function * loadData () {
  yield takeEvery(AccountTypes.GET_ACCOUNT_SETTING_REQUEST, _loadData);
}

function * _loadData ({ payload }) {
  const user = yield select(getAuthUser);
  const api = { ...API_USER.GET_USER_DETAIL };
  api.url += user.id;
  yield createRequest({
    api: api,
    onSuccess: function * (response) {
      const data = Util.toCamelCaseKey(response.data);
      // invisible sidebar when mode is floated (maybe place in selectTodoSaga)
      // get list parent task
      yield put({
        type: AccountTypes.GET_ACCOUNT_SETTING_SUCCESS,
        payload: data
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: AccountTypes.GET_ACCOUNT_SETTING_ERROR,
        payload: status
      });
    }
  });
}

function * updateUserInfo () {
  yield takeEvery(AccountTypes.UPDATE_ACCOUNT_SETTING_REQUEST, _updateUserInfo);
}

function * _updateUserInfo ({ payload }) {
  const path = yield select(getServicePath);
  const api = { ...API_USER.UPDATE_USER_INFO };
  api.url += payload.id;
  const data = {
    full_name: payload.fullName,
    lang: payload.lang
  };
  if (payload.isSubscribeMail !== undefined) {
    data.is_subscribe_mail = payload.isSubscribeMail;
  }
  yield createRequest({
    api: Object.assign({}, api, {
      data: data
    }),
    onSuccess: function * (data) {
      const userRes = data.data;
      // invisible sidebar when mode is floated (maybe place in selectTodoSaga)
      // get list parent task
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${data.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: AccountTypes.UPDATE_ACCOUNT_SETTING_SUCCESS
      });
      yield put({
        type: SessionTypes.GET_AUTH_INFO_REQUEST,
        payload: { path: path }
      });

      const user = yield select(getAuthUser);
      if (user.id === payload.id) {
        yield put({
          type: AccountTypes.GET_ACCOUNT_SETTING_REQUEST,
          payload: {}
        });
      }

      if (userRes.id === user.id) {
        const currentDomain = yield select(getCompanyDomain);
        const servicePath = yield select(getServicePath);
        const userStore = !servicePath || servicePath === '' ? currentDomain : servicePath;
        yield call(StorageHelper.setLocalObject, userStore, Util.toCamelCaseKey(userRes));
        yield put(changeLocale(userRes.lang));
      }
      if (payload.companyId) {
        yield put({
          type: CompanyTypes.GET_COMPANY_DETAIL_REQUEST,
          payload: payload.companyId
        });
      }
    },
    onError: function * ({ status }) {
      yield put({
        type: AccountTypes.UPDATE_ACCOUNT_SETTING_ERROR,
        payload: status
      });
    }
  });
}

function * updateUserNotiPrefer () {
  yield takeEvery(AccountTypes.UPDATE_USER_NOTI_PREFER_REQUEST, _updateUserNotiPrefer);
}

function * _updateUserNotiPrefer ({ payload }) {
  const path = yield select(getServicePath);
  const api = { ...API_USER.UPDATE_USER_INFO };
  api.url += payload.id;
  yield createRequest({
    api: Object.assign({}, api, {
      data: {
        is_receive_notify: payload.isReceiveNotify,
        is_subscribe_mail: payload.isSubscribeMail
      }
    }),
    onSuccess: function * (data) {
      const userRes = data.data;
      // invisible sidebar when mode is floated (maybe place in selectTodoSaga)
      // get list parent task
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${data.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });

      yield put({
        type: SessionTypes.GET_AUTH_INFO_REQUEST,
        payload: { path: path }
      });

      const user = yield select(getAuthUser);
      if (user.id === payload.id) {
        yield put({
          type: AccountTypes.GET_ACCOUNT_SETTING_REQUEST,
          payload: {}
        });
      }

      if (userRes.id === user.id) {
        const currentDomain = yield select(getCompanyDomain);
        const servicePath = yield select(getServicePath);
        const userStore = !servicePath || servicePath === '' ? currentDomain : servicePath;
        yield call(StorageHelper.setLocalObject, userStore, Util.toCamelCaseKey(userRes));
        yield put(changeLocale(userRes.lang));
      }
      if (payload.companyId) {
        yield put({
          type: CompanyTypes.GET_COMPANY_DETAIL_REQUEST,
          payload: payload.companyId
        });
      }
    },
    onError: function * ({ status }) {
      yield put({
        type: AccountTypes.UPDATE_USER_NOTI_PREFER_ERROR,
        payload: status
      });
    }
  });
}

function * resetUserPassword () {
  yield takeEvery(AccountTypes.RESET_USER_PASSWORD_REQUEST, _resetUserPassword);
}

function * _resetUserPassword ({ payload }) {
  const api = { ...API_USER.RESET_USER_PASSWORD };
  api.url += payload;
  yield createRequest({
    api: api,
    onSuccess: function * () {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: 'toast.success.passwordReset',
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
    }
  });
}

function * cancelAccount () {
  yield takeEvery(AccountTypes.CANCEL_ACCOUNT_USER_REQUEST, _cancelAccount);
}

function * _cancelAccount ({ payload }) {
  const api = { ...API_USER.CANCEL_USER, url: `${API_USER.CANCEL_USER.url}/${payload}` };
  yield createRequest({
    api: api,
    onSuccess: function * () {
      yield put({
        type: AccountTypes.CANCEL_ACCOUNT_USER_SUCCESS
      });
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: 'toast.success.cancelUser',
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: SessionTypes.CLEAR_AUTH
      });
      yield put({ type: SessionTypes.REAUTHORIZE });
      const currentDomain = yield select(getCompanyDomain);
      const servicePath = yield select(getServicePath);
      const userStore = !servicePath || servicePath === '' ? currentDomain : servicePath;
      const loginPath = !servicePath || servicePath === '' ? '/login' : `/${servicePath}/login`;
      const viewSettings = yield select(getViewSettings);
      const isFullDomain = viewSettings.domainType === DOMAIN_TYPE.FULL_DOMAIN;

      const authToken = yield call(
        StorageHelper.getCookie,
        userStore
      );
      const authUser = yield call(
        StorageHelper.getLocalObject,
        userStore
      );
      const appMobileAuthToken = yield call(
        StorageHelper.getLocalItem,
        STORAGE_KEYS.appMobileAuthToken
      );
      if (authToken || authUser) {
        yield call(StorageHelper.removeCookie, userStore, isFullDomain ? { path: '/' } : {});
        yield call(StorageHelper.removeLocalItem, userStore);
        yield call(
          StorageHelper.setLocalObject,
          STORAGE_KEYS.googleLogin,
          { isGoogleLogin: false }
        );
        yield call(
          StorageHelper.setLocalObject,
          STORAGE_KEYS.microsoftLogin,
          { isMicrosoftLogin: false }
        );
        yield put(changeRoute(loginPath, ''));

        yield put(changeLocale(Util.getBrowserLanguage() || DEFAULT_LOCALE));
        // yield put(cacheRoute(null, null));
      }
      if (appMobileAuthToken) {
        yield call(StorageHelper.removeLocalItem, STORAGE_KEYS.appMobileAuthToken);
      }
    },
    onError: function * () {
      yield put({
        type: AccountTypes.CANCEL_ACCOUNT_USER_ERROR
      });
    }
  });
}

function * changeUserPassword () {
  yield takeEvery(AccountTypes.CHANGE_PASSWORD_REQUEST, _changeUserPassword);
}

function * _changeUserPassword ({ payload }) {
  const request = {
    user_id: payload.id,
    old_pass: payload.oldPassword,
    new_pass: payload.newPassword,
    confirm_pass: payload.confirmPassword
  };
  yield createRequest({
    api: {
      ...API_USER.CHANGE_USER_PASSWORD,
      data: request
    },
    onSuccess: function * () {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: 'toast.success.passwordChanged',
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: AccountTypes.CHANGE_PASSWORD_SUCCESS,
        payload: {}
      });
    },
    onError: function * ({ status }) {
      yield put({
        type: AccountTypes.CHANGE_PASSWORD_ERROR,
        payload: status
      });
    }
  });
}

function * forgotPassword () {
  yield takeEvery(AccountTypes.FORGOT_PASSWORD_REQUEST, _forgotPassword);
}

function * _forgotPassword ({ payload }) {
  const domain = yield select(getCompanyDomain);
  const servicePath = yield select(getServicePath);
  const domainType = yield select(getDomainType);
  const isFullDomain = domainType === DOMAIN_TYPE.FULL_DOMAIN;
  const request = { email: payload.email, domain: domain, service_path: servicePath, login_type: payload.loginType };
  yield createRequest({
    api: {
      ...API_USER.FORGOT_PASSWORD,
      params: { ...request }
    },
    onSuccess: function * () {
      yield put(changeRoute(`${isFullDomain ? '' : `/${servicePath}`}/forget-password`, `sent=true&email=${payload.email}`));
    }
  });
}

function * changeUserEmail () {
  yield takeEvery(AccountTypes.CHANGE_USER_EMAIL_REQUEST, _changeUserEmail);
}

function * _changeUserEmail ({ payload }) {
  const user = yield select(getAuthUser);
  const request = {
    new_email: payload.email
  };
  const api = { ...API_USER.CHANGE_USER_EMAIL };
  api.url += payload.id;
  yield createRequest({
    api: {
      ...api,
      data: request
    },
    onSuccess: function * (data) {
      yield put({
        type: NotificationTypes.SHOW_NOTIFICATION,
        payload: {
          config: {
            message: `api.update.${data.status}`,
            level: 'success',
            autoDismiss: 3,
            position: 'tc'
          }
        }
      });
      yield put({
        type: AccountTypes.CHANGE_USER_EMAIL_SUCCESS,
        payload: {}
      });
      if (user.id === payload.id) {
        const newUserData = Util.toCamelCaseKey({
          ...user,
          email: payload.email
        });
        const currentDomain = yield select(getCompanyDomain);
        const servicePath = yield select(getServicePath);
        const userStore = !servicePath || servicePath === '' ? currentDomain : servicePath;

        yield call(StorageHelper.setLocalObject, userStore, newUserData);
        yield put({ type: SessionTypes.RESTORE_SESSION_SUCCESS, payload: newUserData });
        yield put({ type: AccountTypes.GET_ACCOUNT_SETTING_REQUEST });
      } else if (payload.companyId && user.roleLevel === USER_CONFIG.SYSTEM_ADMIN.roleLevel) {
        yield put({ type: CompanyTypes.GET_COMPANY_DETAIL_REQUEST, payload: payload.companyId });
      }
    },
    onError: function * ({ status }) {
      yield put({
        type: AccountTypes.CHANGE_USER_EMAIL_ERROR,
        payload: status
      });
    }
  });
}

export default function * accountSaga () {
  yield all([
    loadData(),
    updateUserInfo(),
    resetUserPassword(),
    changeUserPassword(),
    forgotPassword(),
    changeUserEmail(),
    updateUserNotiPrefer(),
    cancelAccount()
  ]);
}
